import React from 'react';

import CareerItem from '../CareerItem';

const CareersList = ({ items }) => {
  return (
    <div className="careers-list">
      {!!items && items.map((item) => <CareerItem key={item.id} item={item} />)}
    </div>
  );
};

export default CareersList;
