const avalailableCategories = [
  'Operations',
  'Business Analysis',
  'Design',
  'Development'
];

const greenhouseCategoryMap = {
  Operations: ['Project Management', 'Supporting Staff', 'Sales'],
  'Business Analysis': ['Sales & Account'],
  Design: ['Design & UX'],
  Development: ['Development', 'Data', 'Software']
};

export const formatData = (data, limit) => {
  const jobs = data.jobs;
  const result = jobs.map((job) => ({
    id: job.id,
    title: job.title,
    location: job.location.name,
    category:
      avalailableCategories.find((category) =>
        greenhouseCategoryMap[category].includes(job.departments[0].name)
      ) || 'Other'
  }));
  return result.slice(0, limit);
};
