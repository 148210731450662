import React from 'react';

import './index.css';
import arrow from '../../img/small-blue-circle-arrow.svg';

const CareerItem = ({ item }) => {
  return (
    <div className="career-item">
      {/* We should really be linking to the careers/listing page but we have been experiencing
      issues embedding the job posting. */}
      {/* <a href={`/careers/listing?gh_jid=${item.id}`}> */}
      <a href={`https://boards.greenhouse.io/devetry/jobs/${item.id}`} target="_blank" rel="noreferrer">
        <>
          <div className="career-heading">
            <p className="title">{item.title}</p>
            <img
              src={arrow}
              alt="go"
            />
          </div>
          <div>
            <p className="location">{item.location}</p>
          </div>
        </>
      </a>
    </div>
  );
};

export default CareerItem;
