import axios from 'axios';
import { formatData } from '../utils/helpers/careersHelpers';

export const getCareers = async (limit) => {
  return await axios
    .get('https://boards-api.greenhouse.io/v1/boards/devetry/jobs?content=true')
    .then((result) => {
      const data = formatData(result.data, limit);
      return data;
    });
};
